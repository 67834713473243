.slots-history-item-desktop {
  background-color: #343434;
  border-radius: 0.625rem;

  &__top {
    display: grid;
    grid-template-columns: repeat(4, 3fr) 1fr;
    width: 100%;
    box-sizing: border-box;
    padding: 0.625rem 1.25rem;
  }

  &__item {
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
  }
  &__item-title {
    margin: 0 0 4px 0;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 0.875rem;    
    color: #888888;
    text-transform: capitalize;

    &_end {
      text-align: end;
    }
  }
  &__item-value {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1rem;
    color: #ffffff;
  }
  &__status {
    display: flex;
    flex-direction: column;
  }
  &__lose {
    margin: auto 0 0 0;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1rem;    
    color: #00CC68;
    text-align: end;
  }
  &__win {
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1rem;
    color: #D31C32;
    text-align: end;
  }
}
/*   &_color {
      &_red {
        background: #fcff6c;
      }
      &_green {
        background: #6cff83;
      }
    } */
