.users-list {
  display: flex;
  flex-direction: column;

  &__title {
    font-size: 1.875rem;
    font-weight: 500;
    line-height: 2.1875rem;
    text-align: left;
    margin-bottom: 1.875rem;
    color: #888888;
  }

  &__filter {
    display: flex;
    align-items: flex-end;
    width: 100%;
    margin-bottom: 1.875rem;
  }

  &__search-wrapper {
    display: flex;
    flex-direction: column;
    margin-right: 0.625rem;
  }
  
  &__label {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1rem;
    text-align: left;
    color: #fff;
    margin-bottom: 2px;
  }

  &__search-input {
    width: 17.875rem;
    height: 2.5rem;
  }

  &__button-wrapper {
    min-width: 5.875rem;
    width: fit-content;
    height: 2.5rem;
    margin-right: 0.4375rem;
  }

  &__button {
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1rem;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
  }

  &__subheader-data-item {
    display: flex;
    align-items: center;
    width: fit-content;
    box-sizing: border-box;
    height: 2.5rem;
    padding: 0 1.25rem;
    border: 1px solid #FFFFFF;
    border-radius: 3.125rem;
    margin-right: 0.625rem;
  }
  
  &__subheader-data-item-nick {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1rem;
    text-align: left;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #ffffff;
    margin-right: 0.625rem;
  }

  &__subheader-data-item-value {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1rem;
    text-align: right;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #00CC68;
  }

  &__list {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__item {
    display: flex;
    align-items: center;
    width: 100%;
    height: 3.125rem;
    box-sizing: border-box;
    padding: 0 0.625rem 0 1.25rem;
    border-radius: 0.625rem;
    background-color: #343434;
    margin-bottom: 1px;
  }

  &__item-text {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1rem;
    text-align: left;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: white;
  }

  &__item-balance {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1rem;
    text-align: left;
    color: #00CC68;
    margin: 0 0.625rem 0 auto;
  }

  &__item-arrow {
    width: 1.875rem;
    height: 1.875rem;
    display: flex;

    * {
      fill: #fff;
    }

    [dir='rtl'] & {
      transform: rotate(180deg);
    }
  }

  &__paginator {
    margin: 1.25rem auto 0;
  }
}
