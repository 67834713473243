.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 3.125rem;
  box-sizing: border-box;
  padding: 0 0.6875rem 0 0.7969rem;
  background-color: #343434;

  &__logo {
    display: flex;
    width: 9.2625rem;
    height: 3.25rem;
  }

  &__user-info {
    display: flex;
    align-items: center;
  }

  &__block {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    box-sizing: border-box;
    padding: 0.3125rem 0.625rem;
    border-radius: 0.625rem;
    background-color: #282828;

    &:not(:last-child) {
      margin-right: 0.4375rem;
    }
  }

  &__value {
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 0.875rem;
    text-align: right;
    color: #fff;

    &_big {
      font-size: 0.875rem;
      font-weight: 700;
      line-height: 1rem;
      text-align: left;
    }
  }

  &__label {
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 0.875rem;
    text-align: right;
    color: #888888;
  }
}
