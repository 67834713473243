.notification {
  z-index: 100;
  position: relative;
  display: block;
  width: 100%;
  margin-top: 16px;
  padding: 13px;
  font-size: 0.875rem;
  animation: 0.7s ease init;

  color: #fff;
  background: #000000;
  border: 1px solid #505050;
  box-shadow: 0px 5px 10px rgba(46, 66, 98, 0.05);
  border-radius: 0.625rem;

  &_type {
    &_default {
    }

    &_error {
    }

    &_success {
    }
  }

  &_deleting {
    animation: 0.7s ease delete;
  }

  &__left {
    height: 100%;
    flex-shrink: 0;
  }

  &__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }
  &__icon-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-right: 0.5rem;
  }
  &__icon {
    display: flex;
    width: 1.25rem;
    cursor: pointer;
  }
  &__text {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
  }

  &__cross-wrapper {
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: auto;
  }

  &__cross {
    margin-left: 0.5rem;
    display: flex;
    cursor: pointer;
    width: 0.8125rem;

    * {
      fill: #fff;
    }
  }

  @keyframes init {
    0% {
      opacity: 0;
      transform: translateX(160px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes delete {
    0% {
      opacity: 1;
      height: 64px;
    }
    70% {
      opacity: 0.5;
      transform: translateX(120%);
      height: 64px;
    }
    100% {
      opacity: 0;
      transform: translateX(200%);
      height: 0;
    }
  }
}
