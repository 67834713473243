.transaction-history {
  &__title {
    font-size: 1.875rem;
    font-weight: 500;
    line-height: 2.1875rem;
    color: #888888;
    margin-bottom: 1.25rem;
  }

  &__content {
    flex-grow: 2;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__fields-title {
    margin: 0 0 0.4375rem 0;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: #797b83;
  }
  &__fields {
    min-width: 32.46%;
    width: fit-content;
    display: grid;
    grid-template-columns: 10.375rem 10.375rem 7.875rem;
    grid-gap: 2px 0.5rem;
    margin: 0 0 1.25rem 0;
  }
  &__field-title {
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
  }
  &__field-input {
    width: 100%;
    height: 2.5rem;
  }

  &__button {
    width: 100%;
    height: 2.5rem;
    margin: 0 0 0.625rem 0;
  }
  &__button-text {
    font-weight: 600;
    font-size: 0.875rem;
    text-align: center;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #111522;
  }

  &__result-wrapper {
    border-radius: 0.625rem;
    overflow: hidden;
  }

  &__result-header {
    height: 2.8125rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    margin: 0 0 1px 0;
    padding: 0 1.25rem;
  }

  &__row {
    height: 2.8125rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    margin: 0 0 1px 0;
    padding: 0 1.25rem;
    background-color: #343434;
    border-radius: 0.625rem;
  }

  &__td {
    overflow: auto;
    width: 100%;
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 0.875rem;    
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #ffffff;

    &:not(:first-child) {
      text-align: center;
    }

    &::-webkit-scrollbar {
      height: 0;
    }
  }

  &__th {
    overflow: auto;
    width: 100%;
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 0.875rem;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #fff;

    &:not(:first-child) {
      text-align: center;
    }

    &::-webkit-scrollbar {
      height: 0;
    }
  }

  &__totals {
    width: 100%;
    height: 2.1875rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2.5rem;
    background: #343434;
  }
  &__totals-withdrawal,
  &__totals-topUp {
    display: flex;
    align-items: center;
  }
  &__totals-withdrawal-title,
  &__totals-withdrawal-value,
  &__totals-topUp-title,
  &__totals-topUp-value {
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 0.875rem;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #ffffff;
  }
  &__totals-withdrawal-value,
  &__totals-topUp-value {
    color: #00CC68;
  }
  &__totals-withdrawal-title,
  &__totals-topUp-title {
    margin: 0 0.625rem 0 0;
  }

  &__paginator-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto 0 0 0;
    padding: 0.625rem 0;
  }
  &__paginator {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
