.tree {
  width: 100%;
  overflow-x: auto;
  padding: 0 0 6.25rem 0;
  color: #fff;

  &__title {
    font-size: 1.875rem;
    font-weight: 500;
    line-height: 2.1875rem;
    color: #888888;
    margin-bottom: 1.25rem;
  }
}
