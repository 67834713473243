.button {
  user-select: none;
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.5rem;
  border: none;
  outline: none;
  user-select: none;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1rem;
  color: #fff;
  font-family: 'Robot', sans-serif;
  text-align: center;
  white-space: nowrap;
  background: transparent;
  min-height: 2.5rem;
  text-transform: uppercase;

  &:active {
    transform: translateY(2px);
  }

  &_borderRadius {
    &_none {
      border-radius: 0;
    }
    &_default {
      border-radius: 0.25rem;
    }
    &_15 {
      border-radius: 0.9375rem;
    }
    &_5 {
      border-radius: 0.3125rem;
    }
  }
  &_padding {
    &_default {
      padding: 0 1.25rem;
    }
  }

  &_color {
    &_default {
      background: #00CC68;
      color: #fff;
      border-radius: 3.75rem;
    }
    &_pale {
      background: #013220;
      opacity: 0.3;
      color: #fff;
    }
    &_grey {
      background: #505050;
      color: #fff;
    }
    &_red-bordered {
      color: #D31C32;
      border: 1px solid #D31C32;
      border-radius: 3.75rem;
      background: transparent;
    }
    &_transparent {
      color: #fff;
    }
    &_change-lang {
      background: linear-gradient(90deg, #c09a37 0%, #f6ea86 51.04%, #cfa946 100%);
      color: #011b11;
    }
    &_green {
      background: #011B11;
      border-radius: 0.625rem;
      color: #fff;
    }
    &_bordered {
      color: #fff;
      border: 1px solid #FFFFFF;
      background: transparent;
      border-radius: 3.75rem;
    }
    &_red {
      background: #D31C32;
      border-radius: 3.75rem;
    }
  }
  &:hover {
  }
  &:hover#{&}_color {
    &_default {
    }
  }

  &_disabled {
    cursor: not-allowed;
    background: #ffb401;
    filter: brightness(0.8);
  }
}
