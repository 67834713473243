.total {
  &__title {
    font-size: 1.875rem;
    font-weight: 500;
    line-height: 2.1875rem;
    text-align: left;
    color: #888888;
    margin-bottom: 1.25rem;
  }

  &__content {
    width: 100%;
  }

  &__fields-title {
    margin: 0 0 0.4375rem 0;
    font-weight: 600;
    font-size: 0.875rem;
  }

  &__fields {
    min-width: 32.46%;
    width: fit-content;
    display: grid;
    grid-template-columns: 10.375rem 10.375rem 7.875rem;
    grid-gap: 2px 0.5rem;
    margin: 0 0 0.4375rem 0;
  }
  &__field-title {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1rem;
    text-align: left;
  }

  &__field-input {
    width: 100%;
    height: 2.5rem;
  }

  &__button {
    width: 100%;
    height: 2.5rem;
    margin: 0 0 0.625rem 0;
  }
  &__button-text {
    font-weight: 600;
    font-size: 0.875rem;
    text-align: center;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #111522;
  }

  &__result {
    width: 100%;
    overflow: hidden;
  }

  &__result-header {
    height: 2.1875rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    margin: 0 0 1px 0;
    padding: 0 1.25rem;
  }

  &__row {
    height: 2.1875rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    margin: 0 0 1px 0;
    padding: 0 1.25rem;
    background-color: #343434;
  }

  &__th {
    overflow: auto;
    width: 100%;
    font-weight: 600;
    font-size: 0.75rem;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #fff;

    &:not(:first-child) {
      text-align: center;
    }

    &::-webkit-scrollbar {
      height: 0;
    }

    &_accented {
      font-size: 1.125rem;
      font-weight: 600;
      line-height: 1.3125rem;      
      color: #00CC68;
    }
  }

  &__td {
    overflow: auto;
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 0.875rem;    
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #fff;

    &:not(:first-child) {
      text-align: center;
    }

    &::-webkit-scrollbar {
      height: 0;
    }

    &_accented {
      font-size: 1.125rem;
      font-weight: 600;
      line-height: 1.3125rem;      
      color: #00CC68;
    }
  }
}
