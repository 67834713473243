.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 0 1.25rem;

  &__title {
    width: 100%;
    color: #888888;
    margin-bottom: 0.625rem;
  }

  &__content {
    width: 100%;
  }

  &__item {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.625rem 0.625rem 0.625rem 1.25rem;
    border: 1px solid #888888;
    border-radius: 3.75rem;
    cursor: pointer;
    user-select: none;

    &:not(:last-child) {
      margin-bottom: 0.625rem;
    }

    &_active {
      background-color: #00CC68;
      border-color: transparent;
    }
  }

  &__item_active &__item-img * {
    fill: #fff;
  }

  &__item-img {
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    margin-right: 0.625rem;
  }

  &__item-text {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1rem;
    text-align: left;
    font-feature-settings: 'pnum' on, 'lnum' on;
  }
  &__element-arrow {
    width: 1.875rem;
    height: 1.875rem;
    display: flex;
    margin: 0 0 0 auto;

    [dir='rtl'] & {
      transform: rotate(180deg);
    }
  }
}
