.paginator-desktop {
  display: flex;
  align-items: center;
  width: fit-content;
  height: 2.5rem;
  box-sizing: border-box;
  padding: 0.3125rem;
  border-radius: 5.625rem;
  background-color: #343434;

  &__arrow {
    display: flex;
    cursor: pointer;
    user-select: none;
    &-svg {
      width: 1.875rem;
      height: 1.875rem;
    }

    &_right {
      transform: scaleX(-1);
    }
  }

  &__page {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 2.875rem;
    margin: 0 0.3125rem;
    box-sizing: border-box;
    border-bottom: 1px solid #888888;
    font-size: 0.875rem;
    font-weight: 900;
    line-height: 1.125rem;
    text-align: center;
    color: #fff;
  }
}
