.locale {
  height: 100%;
  max-width: 21.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  &_forAuth {
    min-width: 44.125rem;
    padding: 6.9375rem 11.4375rem 5.9375rem;
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    margin-bottom: 1.25rem;
    font-weight: 500;
    font-size: 1.875rem;
    color: #797b83;
    text-align: center;
  }

  &__item {
    width: 100%;
    height: 3.125rem;
    display: flex;
    align-items: center;
    padding: 0.625rem;
    margin: 0 0 0.625rem 0;
    border: 1px solid #fff;
    border-radius: 5.625rem;
    cursor: pointer;
    color: #fff;
  }
  &__item_active {
    background: #00CC68;
    border: none;
    color: #fff;
  }

  &__item-flag {
    width: 1.875rem;
    margin-right: 0.625rem;
  }
  &__item-text {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1rem;
    letter-spacing: 0em;
    text-align: left;
  }

  &__bottom {
    margin-top: 17.0625rem;
    width: 100%;

    &_small {
      margin-top: 1.25rem;
    }
  }
  &__button {
    width: 100%;
    height: 2.5rem;
  }
  &__button-text {
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1rem;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
  }

  &__title {
    font-size: 1.875rem;
    font-weight: 500;
    line-height: 2.1875rem;
    text-align: center;
    color: #888888;
    margin-bottom: 1.25rem;
  }
}
