.menu-item {
  width: 100%;
  display: flex;
  align-items: center;

  &__name {
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 0.875rem;
    text-align: left;
    color: #888888;
    margin-bottom: 0.625rem;
  }

  &__sub-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 0.625rem;
    }
  }

  &__element {
    padding: 0 0.625rem 0 1.25rem;
    width: 100%;
    height: 3.125rem;
    border: 1px solid #888888;
    border-radius: 3.75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:not(:last-child) {
      margin: 0 0 0.625rem 0;
    }

    &_active {
      background: #00CC68;
      border-color: transparent;
      color: #fff;
    }
  }

  &__element-name {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1rem;
    text-align: left;
    font-feature-settings: 'pnum' on, 'lnum' on;
  }

  &__element-indicator {
    position: relative;
    width: 1.25rem;
    min-width: 1.25rem;
    height: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 1.25rem 0 auto;
  }
  &__element-indicator-background {
    position: absolute;
    width: 1.25rem;
    min-width: 1.25rem;
    height: 1.25rem;
    background-color: red;
    border-radius: 50%;
    transform: scale(1);
    animation: 1s infinite alternate indicate;
  }
  &__element-indicator-count {
    z-index: 1;
    font-size: 0.75rem;
    line-height: 0.75rem;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: white;
  }
  &__element-arrow {
    width: 1.875rem;
    height: 1.875rem;
    display: flex;

    [dir='rtl'] & {
      transform: rotate(180deg);
    }
  }

  @keyframes indicate {
    from {
      transform: scale(1);
    }

    to {
      transform: scale(1.2);
    }
  }
}
