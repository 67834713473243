.auth-modal {
  padding: 7.5625rem 0.625rem 9.625rem;
  min-width: 44.125rem;

  &__pop-up-title {
    font-size: 1.875rem;
    font-weight: 500;
    line-height: 2.1875rem;
    text-align: center;
    color: #888888;
    margin-bottom: 2.8125rem;
  }

  &__buttons {
    margin: 0 auto;
    width: 20rem;
  }

  &__pop-up-button {
    height: 2.5rem;

    &:not(:last-child) {
      margin-bottom: 1.4375rem;
    }
  }

  &__pop-up-button-text {
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1rem;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
  }
}
