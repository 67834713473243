.change-pas {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  &__title {
    font-size: 1.875rem;
    font-weight: 500;
    line-height: 2.1875rem;
    text-align: center;
    color: #888888;
    margin-bottom: 1.25rem;
  }

  &__content {
    width: 21.25rem;
  }

  &__main {
    width: 100%;
  }

  &__form {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  &__item {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 1.25rem;
  }

  &__item-title {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1rem;
    text-align: left;
    margin-bottom: 2px;
  }

  &__item-field {
    widows: 100%;
    height: 2.5rem;
  }

  &__button {
    width: 100%;
    height: 2.5rem;
  }

  &__button-text {
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1rem;
    text-align: center;
    color: #fff;
  }
}
