.pop-up {
  z-index: 5;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;

  &__header {
    width: 100%;
    padding: 1.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    font-size: 1rem;
    background-color: #282828;
    border-radius: 0.625rem 0.625rem 0 0;
  }

  &__cross-container {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &__cross {
    width: 0.8125rem;
    height: 0.8125rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__content {
    max-width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #343434;
    box-shadow: 0px 5px 10px rgba(46, 66, 98, 0.05);
    border-radius: 0.625rem;
  }
}
