.create-user {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  &__title {
    margin-bottom: 13.5625rem;
    font-weight: 700;
    font-size: 1.875rem;
  }

  &__sub-title {
    font-size: 1.875rem;
    font-weight: 500;
    line-height: 2.1875rem;
    text-align: center;
    color: #888888;
    margin-bottom: 1.25rem;
  }

  &__content {
    margin: 0 auto;
    width: 21.25rem;
  }

  &__field {
    width: 100%;
    margin-bottom: 1.25rem;
  }

  &__field-title {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1rem;
    text-align: left;
    margin: 0 0 2px 0;
  }

  &__field-input {
    width: 100%;
    height: 2.5rem;
  }

  &__button {
    height: 2.5rem;
    width: 100%;
  }
}
